<template>
  <div class="detail_content">
    <div v-if="show" class="flex flex-col">
      <div class="text-left pb-10">
        <h1 class="text-4xl text-blue-default font-semibold">
          {{ detail.name }}
        </h1>
      </div>
      <!-- card -->
      <div
        class="card_content w-full p-8 bg-white rounded-lg relative mb-40 dark:bg-card-dark"
      >
        <img
          :src="detail.cover ? detail.cover.url : defaultCover"
          alt=""
          class="w-full rounded-lg lg:max-h-80"
          style="object-fit: cover"
        />
        <div
          class="flex lg:justify-start justify-center w-full absolute -bottom-24 left-0"
        >
          <div class="w-48 p-2 rounded-full bg-white lg:ml-20">
            <img :src="detail.icon.url" class="w-full rounded-full" />
          </div>
        </div>
      </div>
      <!-- des -->
      <div
        v-if="detail.des"
        class="w-full text-left text-2xl pb-10 antialiased des dark:text-gray-400"
        v-html="detail.des.html"
      ></div>
      <!-- tools -->
      <div class="">
        <div class="flex justify-start items-center pb-10">
          <svg-icon
            icon-class="tag"
            class="text-white text-opacity-75 w-8 h-8 dark:text-gray-300 group-hover:text-blue-default"
          ></svg-icon>
          <span class="text-2xl font-semibold">Similar Tools</span>
        </div>
        <div
          class="list_content py-4 grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xsm:grid-cols-2 gap-x-4 gap-y-7"
        >
          <div
            class="bg-white rounded-lg dark:bg-card-dark cursor-pointer transition hover:-translate-y-4 group ease-in-out"
            v-for="(item, index) in tools"
            :key="index"
            @click="toDetail(item.id)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.subtitle"
              placement="bottom"
              :open-delay="300"
            >
              <cardList :item="item" class="" />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-40" v-else>
      <svg-icon
        icon-class="loding"
        class="text-white text-opacity-75 w-16 h-16 animate-spin dark:text-gray-300 group-hover:text-blue-default"
      ></svg-icon>
    </div>
  </div>
</template>
<script>
import { gql } from "graphql-request";
import cardList from "@/components/cardList/index";
export default {
  // metaInfo: {
  //   title: "我是 about 页面的title",
  //   meta: [
  //     {
  //       name: "keywords",
  //       content: "我是 about 页面的keywords",
  //     },
  //     {
  //       name: "description",
  //       content: "我是 about 页面的description",
  //     },
  //   ],
  // },
  inject: ["reload"],
  components: { cardList },
  data() {
    return {
      id: "",
      parentId: "",
      detail: {
        cover: {
          url: "",
        },
      },
      tools: [],
      show: false,
      defaultCover:
        "https://img.tukuppt.com/bg_grid/00/03/29/BMuPp0QzDQ.jpg!/fh/350",
    };
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    next((vm) => {
      vm.getId(to.query.ids);
      vm.getDetail();
      vm.getAsTools();
    });
  },
  created() {
    // this.id = this.$route.query.ids[1];
    // this.getDetail();
  },
  methods: {
    getId(ids) {
      this.show = false;
      this.id = ids[1];
      this.parentId = ids[0];
    },
    async getDetail() {
      const { tools } = await this.$hygraph.request(
        gql`
          query Assets($id: ID) {
            tools(where: { id: $id }) {
              id
              like
              look
              name
              subtitle
              url
              icon {
                fileName
                url
              }
              cover {
                fileName
                url
              }
              des {
                html
              }
            }
          }
        `,
        {
          id: this.id,
        }
      );
      this.detail = Object.assign(this.detail, tools);
      this.show = true;
      // console.log(tools);
    },
    async getAsTools() {
      console.log(this.parentId);
      const { typeChildren } = await this.$hygraph.request(
        gql`
          query Assets($id: ID, $parentId: ID) {
            typeChildren(where: { id: $parentId }) {
              id
              name
              tools(where: { id_not: $id }, first: 20) {
                id
                name
                subtitle
                url
                icon {
                  fileName
                  url
                }
              }
            }
          }
        `,
        {
          id: this.id,
          parentId: this.parentId,
        }
      );
      this.tools = typeChildren[0].tools;
      // console.log(typeChildren[0].tools);
    },
    toDetail(selfId) {
      this.show = false;
      this.id = selfId;
      this.getDetail();
    },
    showRouter() {
      // 调用reload方法，刷新整个页面
      this.reload();
    },
  },
  beforeDestroy() {
    this.$nextTick(() => {
      this.id = "";
      this.parentId = "";
      this.detail = {};
      this.tools = [];
      this.show = false;
    });
  },
};
</script>
<style lang="scss" scoped>
.des {
  ::v-deep p {
    line-height: 150%;
    margin: 5px 0;
  }
  ::v-deep li {
    position: relative;
    > div {
      line-height: 150%;
      list-style: disc;
      margin-left: 20px;
    }
  }
}
</style>

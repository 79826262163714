<template>
  <div
    class="logo_content flex items-center justify-center border-my-gray border-r border-b"
  >
    <img :src="logo1" class="logo" alt="AI工具集导航" v-show="isCollapse" />
    <img :src="logo2" class="w-12" alt="" v-show="!isCollapse" />
  </div>
</template>
<script>
export default {
  name: "logoCom",
  computed: {
    isCollapse() {
      return !this.$store.getters.isCollapse;
    },
    isMobile() {
      return !this.$store.getters.isMobile;
    },
  },
  data() {
    return {
      logo1: require("@/assets/imgs/logo-light.png"),
      logo2: require("@/assets/imgs/icon-light.png"),
      logo3: require("@/assets/imgs/logo.png"),
    };
  },
  mounted() {
    // console.log(this.$store.getters.isCollapse);
  },
};
</script>

<style lang="scss" scoped>
.logo_content {
  height: 60px;
  // border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  .logo {
    width: 132px;
  }
}
</style>

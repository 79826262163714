<template>
  <el-container class="continer relative">
    <el-aside
      class="flex flex-col bg-aside-light border-none"
      v-show="isMobile"
      v-if="!loading"
    >
      <logoCom />
      <menAside class="pt-4" />
    </el-aside>
    <el-container
      class="bg-theme-light dark:bg-theme-dark bg-no-repeat bg-contain"
    >
      <el-header class="bg-white dark:bg-aside-light">
        <myHeader @showMenu="showMenu" />
      </el-header>
      <el-main>
        <keep-alive>
          <router-view v-if="isShow"></router-view>
        </keep-alive>
      </el-main>
      <!-- <el-footer></el-footer> -->
    </el-container>

    <!-- drawer -->
    <el-drawer
      :withHeader="false"
      :show-close="false"
      size="220px"
      :visible.sync="show_menu"
    >
      <div class="w-full h-full bg-aside-light">
        <logoCom />
        <menAside @closeMune="showMenu" />
      </div>
    </el-drawer>
  </el-container>
</template>
<script>
import { gql } from "graphql-request";
import menAside from "@/components/layout/aside/index.vue";
import myHeader from "@/components/layout/header/index.vue";
import logoCom from "@/components/layout/logo";
export default {
  name: "layOut",
  components: { menAside, myHeader, logoCom },
  computed: {
    isMobile() {
      return !this.$store.getters.isMobile;
    },
  },
  data() {
    return {
      show_menu: false,
      loading: true,
      isShow: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  created() {
    this.getMeuns();
  },
  methods: {
    showMenu() {
      this.show_menu = !this.show_menu;
    },
    reload() {
      // 先隐藏
      this.isShow = false;

      // $nextTick() 将回调延迟到下次 DOM 更新循环之后执行
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    async getMeuns() {
      const { toolTypes } = await this.$hygraph.request(
        gql`
          query Assets {
            toolTypes {
              id
              name
              icon {
                url
                fileName
              }
              childs {
                name
                id
                tools(first: 100) {
                  id
                  name
                  look
                  like
                  url
                  icon {
                    url
                    fileName
                  }
                  des {
                    html
                  }
                  subtitle
                  parent {
                    ... on TypeChild {
                      id
                      name
                    }
                  }
                }
              }
              tools(first: 100) {
                name
                id
                url
                like
                look
                icon {
                  fileName
                  url
                }
                des {
                  html
                }
                subtitle
                parent {
                  ... on ToolType {
                    id
                    name
                  }
                }
              }
            }
          }
        `
      );
      toolTypes.map((v) => {
        let fileName = v.icon.fileName.replace(/\.svg$/, "");
        v.icon.fileName = fileName;
        return v;
      });
      this.loading = false;
      this.$store.dispatch("set_list", toolTypes);
    },
  },
};
</script>

<style lang="scss" scoped>
.continer {
  width: 100vw;
  height: 100vh;
  ::v-deep .el-loading-spinner {
    display: flex;
    justify-content: center;
  }
}
.el-aside {
  // border-right: solid 1px #e6e6e6;
  text-align: center;
  width: auto !important;
  max-width: 200px;
  line-height: 200px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.contentbg {
  background-image: url("https://ai-bot.cn/wp-content/uploads/2023/03/blur-shape.png");
}
</style>

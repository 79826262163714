<template>
  <div class="cardList flex items-center px-6 py-6">
    <img :src="item.icon.url" class="w-16 xsm:w-14" />
    <div class="flex-1 flex flex-col items-start ml-4 overflow-hidden">
      <div class="truncate w-full text-left">
        <span
          class="text-2xl font-semibold xsm:text-xl dark:text-blue-default group-hover:text-blue-500 dark:group-hover:text-white"
          >{{ item.name }}</span
        >
      </div>
      <div class="truncate w-full text-left mt-1">
        <span class="text-base xsm:text-sm text-gray-400">{{
          item.subtitle
        }}</span>
      </div>
    </div>
  </div>
  <!-- 
    <el-tooltip
    class="item"
    effect="dark"
    :content="item.subtitle"
    placement="bottom"
  >
    <div class="cardList flex items-center px-6 py-6">
      <img :src="item.icon.url" class="w-16 xsm:w-14" />
      <div class="flex-1 flex flex-col items-start ml-4 overflow-hidden">
        <div class="truncate w-full text-left">
          <span
            class="text-2xl font-semibold xsm:text-xl dark:text-gray-300 group-hover:text-blue-500 dark:group-hover:text-white"
            >{{ item.name }}</span
          >
        </div>
        <div class="truncate w-full text-left mt-1">
          <span class="text-base xsm:text-sm text-gray-400">{{
            item.subtitle
          }}</span>
        </div>
      </div>
    </div>
  </el-tooltip>
   -->
</template>
<script>
export default {
  name: "cardList",
  props: ["item"],
};
</script>
<style lang="scss" scoped>
.cardList {
  position: relative;
}
</style>

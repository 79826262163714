<template>
  <el-menu
    class="el-menu-vertical"
    :unique-opened="true"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    @select="handleSelect"
  >
    <div v-for="(item, index) in list" :key="index">
      <el-submenu :index="item.id" v-if="item.childs.length > 1">
        <template slot="title">
          <div class="group" style="padding: 0 20px">
            <svg-icon
              :icon-class="item.icon.fileName"
              class="text-white text-opacity-75 w-8 h-8 dark:text-gray-300 group-hover:text-blue-default"
            ></svg-icon>
            <span
              slot="title"
              class="ml-4 text-white text-opacity-75 group-hover:text-blue-default"
              v-show="!isCollapse"
              >{{ item.name }}</span
            >
          </div>
        </template>
        <el-menu-item-group>
          <el-menu-item
            :index="child.id"
            v-for="(child, i) in item.childs"
            :key="i"
            class="dark:hover:bg-gray-500 dark:focus-within:bg-transparent focus-within:bg-transparent group"
          >
            <div
              class="pl-5 h-16 w-full"
              :class="[
                selectMuen == child.id && isMobile
                  ? 'bg-theme-light dark:bg-theme-dark rounded-my'
                  : '',
              ]"
            >
              <div class="content_muen">
                <div
                  class="shape bg-theme-light dark:bg-theme-dark"
                  v-if="selectMuen == child.id && isMobile"
                ></div>
                <span
                  class="text-opacity-75 group-hover:text-blue-default"
                  :class="[
                    selectMuen == child.id ? 'text-blue-default' : 'text-white',
                  ]"
                  >{{ child.name }}</span
                >
              </div>
            </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item
        :index="item.id"
        v-else
        class="dark:hover:bg-gray-500 dark:focus-within:bg-transparent focus-within:bg-transparent hover:text-blue-default group"
      >
        <div
          class="pl-8 h-16 w-full"
          :class="[
            selectMuen == item.id && isMobile
              ? 'bg-theme-light dark:bg-theme-dark rounded-my'
              : '',
          ]"
        >
          <div
            class="shape bg-theme-light dark:bg-theme-dark"
            v-if="selectMuen == item.id && isMobile"
          ></div>
          <div class="content_muen">
            <svg-icon
              :icon-class="item.icon.fileName"
              class="w-8 h-8 text-opacity-75 group-hover:text-blue-default"
              :class="[
                selectMuen == item.id ? 'text-blue-default' : 'text-white',
              ]"
            ></svg-icon>
            <span
              slot="title"
              class="ml-4 text-opacity-75 group-hover:text-blue-default"
              :class="[
                selectMuen == item.id ? 'text-blue-default' : 'text-white',
              ]"
              v-show="!isCollapse"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
export default {
  name: "menAside",
  data() {
    return {
      //   isCollapse: false,
      //   list: [],
      selectMuen: "",
    };
  },
  computed: {
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
    list() {
      return this.$store.getters.getList;
    },
    isMobile() {
      return !this.$store.getters.isMobile;
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      //   console.log(key, keyPath);
      this.$store.dispatch("add_activeNum", { index: key, indexPath: keyPath });
    },
    handleClose(key, keyPath) {
      this.$store.dispatch("add_activeNum", { index: key, indexPath: keyPath });
    },
    handleSelect(index, indexPath) {
      if (!(this.$route.name == "home")) {
        this.$router.push({ name: "home" });
        // return;
      }
      this.selectMuen = index;
      this.$emit("closeMune");
      this.$store.dispatch("add_activeNum", { index, indexPath });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  //   height: 100%;
  background: none;
  color: #fff;
}
.el-menu-vertical.el-menu--collapse {
  ::v-deep .el-submenu__icon-arrow {
    display: none;
  }
}
.el-menu-vertical {
  background: none;
  border-right: none;
  .el-menu-item {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0;
    height: 56px;
    padding: 0 !important;
  }
  .el-menu-item-group .el-menu-item {
    padding-left: 20px !important;
  }
  .content_muen {
    height: 4rem;
    display: flex;
    align-items: center;
  }
  ::v-deep .el-menu {
    background: none !important;
  }
  ::v-deep .el-submenu__title {
    text-align: left;
  }
  ::v-deep .el-menu-item-group__title {
    display: none;
  }
  ::v-deep .el-submenu__title {
    padding: 0 !important;
  }
  // ::v-deep .el-menu-item.is-active {
  //   background-color: #eaf0f5;
  //   position: relative;
  // }
  ::v-deep .el-menu-item:hover {
    background: none;
  }
  ::v-deep .el-submenu__title:hover {
    background: none;
  }
  .shape {
    width: 20px;
    height: 85px;
    position: absolute;
    top: -15px;
    right: 0;
  }
  ::v-deep .el-menu-item.is-active::before {
    content: "";
    position: absolute;
    top: -22px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 0px 48px 0px;
    border-right: 20px solid rgb(14, 14, 35);
  }
  ::v-deep .el-menu-item.is-active::after {
    content: "";
    position: absolute;
    // top: 43px;
    z-index: 0;
    right: 0px;
    bottom: -22px;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 48px 0px 0px;
    border-right: 20px solid rgb(14, 14, 35);
  }
  .rounded-my {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
</style>

<template>
  <div class="h-full flex items-center bg-transparent">
    <div class="flex justify-between items-center w-full" v-if="!isMobile">
      <div
        class="bg-gray-200 bg-opacity-20 p-2 rounded-full cursor-pointer dark:bg-black flex"
      >
        <img :src="darkImg" class="w-6 dark:w-0" @click="darkMode" />
        <img :src="light" class="w-0 dark:w-6" @click="lightMode" />
      </div>
      <div @click="showMenu">
        <svg-icon
          icon-class="meun-ligth-m"
          class="text-current dark:w-0 w-8 h-8 dark:text-gray-300 cursor-pointer rotate-90"
        ></svg-icon>
        <svg-icon
          icon-class="meun-dark-m"
          class="text-current w-0 dark:w-8 h-8 dark:text-gray-300 cursor-pointer rotate-90"
        ></svg-icon>
      </div>
    </div>

    <div class="flex w-full justify-between" v-else>
      <div @click="setCollapse" class="cursor-pointer">
        <svg-icon
          icon-class="menu"
          className="icon"
          class="text-black dark:visible w-0 dark:w-16 h-8"
        ></svg-icon>
        <svg-icon
          icon-class="menu-dark"
          className="icon"
          class="text-black dark:invisible dark:w-0 w-16 h-8"
        ></svg-icon>
      </div>
      <div
        class="bg-gray-200 bg-opacity-20 p-2 rounded-full cursor-pointer dark:bg-black flex"
      >
        <img :src="darkImg" class="w-6 dark:w-0" @click="darkMode" />
        <img :src="light" class="w-0 dark:w-6" @click="lightMode" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "myHeader",
  data() {
    return {
      isCollapse: false,
      darkImg: require("@/assets/imgs/asIcons/dark.png"),
      light: require("@/assets/imgs/asIcons/shensemoshi.png"),
    };
  },
  computed: {
    isMobile() {
      return !this.$store.getters.isMobile;
    },
  },
  methods: {
    setCollapse() {
      this.$store.dispatch("set_isCollapse");
    },
    showMenu() {
      this.$emit("showMenu");
    },
    darkMode() {
      //这个条件用于判断当前系统应用模式是否开启了“暗”模式（win10在   个性化-颜色-选择默认应用模式  中修改）
      // if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      if (localStorage.theme === "dark" || !("theme" in localStorage)) {
        document.documentElement.classList.add("dark");
        localStorage.theme = "dark";
      } else {
        document.documentElement.classList.remove("dark");
        localStorage.theme = "light";
      }
    },
    lightMode() {
      document.documentElement.classList.remove("dark");
    },
  },
};
</script>

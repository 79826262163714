import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MetaInfo from "vue-meta-info";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/tailwindcss.css";
import "./icons";

import { GraphQLClient } from "graphql-request";

const hygraphClient = new GraphQLClient(
  "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clha7xky513kd01t5aeca2ro1/master"
  // "https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/clha7xky513kd01t5aeca2ro1/master" //只读api
);

Vue.mixin({
  beforeCreate() {
    this.$hygraph = hygraphClient;
  },
});

Vue.use(MetaInfo);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");

<template>
  <div class="home_content">
    <div v-if="!lodding">
      <div
        v-for="(item, index) in list"
        :key="index"
        :id="item.id"
        :ref="item.id"
      >
        <div class="tabs mt-6" v-if="item.childs.length > 1">
          <el-tabs :value="getActiveName(item.id)">
            <el-tab-pane
              :name="item1.id"
              v-for="(item1, i) in item.childs"
              :key="i"
              :stretch="true"
            >
              <template slot="label">
                <span class="dark:text-white">{{ item1.name }}</span>
              </template>
              <div
                class="list_content py-4 grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xsm:grid-cols-2 gap-x-4 gap-y-7"
              >
                <div
                  class="bg-white rounded-lg dark:bg-card-dark cursor-pointer transition hover:-translate-y-4 group ease-in-out rounded-my-2"
                  v-for="(item2, index2) in item1.tools"
                  :key="index2"
                  @click="toDetail(item1.id, item2.id)"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item2.subtitle"
                    placement="bottom"
                    :open-delay="300"
                  >
                    <cardList :item="item2" class="" />
                  </el-tooltip>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else>
          <div class="my-6 flex justify-start">
            <svg-icon
              :icon-class="item.icon.fileName"
              class="text-current w-8 h-8 dark:text-white"
            ></svg-icon>
            <span class="ml-4 font-semibold text-2xl dark:text-white">{{
              item.name
            }}</span>
          </div>
          <div
            class="list_content py-4 grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xsm:grid-cols-2 gap-x-4 gap-y-7"
          >
            <div
              class="bg-white rounded-lg dark:bg-card-dark cursor-pointer transition hover:-translate-y-4 group ease-in-out rounded-my-2"
              v-for="(item3, index3) in item.childs[0].tools"
              :key="index3"
              @click="toDetail(item.childs[0].id, item3.id)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item3.subtitle"
                placement="bottom"
                :open-delay="300"
              >
                <cardList :item="item3" class="" />
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-40">
      <svg-icon
        icon-class="loding"
        class="text-white text-opacity-75 w-16 h-16 animate-spin dark:text-gray-300 group-hover:text-blue-default"
      ></svg-icon>
    </div>
  </div>
</template>

<script>
import cardList from "@/components/cardList/index";
export default {
  metaInfo: {
    title: "AI Meta Club - AI Tools",
    meta: [
      {
        name: "keywords",
        content: "AI Meta Club , AI Tools",
      },
      // {
      //   name: "description",
      //   content: "我是 home 页面的description",
      // },
    ],
  },
  components: { cardList },
  data() {
    return {
      // list: [],
      lodding: true,
      activeNames: {},
    };
  },
  computed: {
    list() {
      return this.$store.getters.getList;
    },
    activeNum() {
      return this.$store.getters.getActiveNum;
    },
    activeData() {
      return this.$store.getters.getActiveData;
    },
  },
  watch: {
    activeNum: {
      handler() {
        this.go();
      },
    },
    list: {
      handler() {
        this.getActiveNames();
      },
      immediate: false,
    },
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    next((vm) => {
      if (from.name == "about") {
        vm.getActiveNames();
      }
    });
  },
  methods: {
    // handleClick(tab, envet) {
    //   console.log(tab, envet);
    // },
    getActiveName(str) {
      // console.log(str);
      return this.activeNames[str];
    },
    getActiveNames() {
      let arr = {};
      let list = this.$store.getters.getList;
      for (let i = 0; i < list.length; i++) {
        if (list[i].childs.length) {
          arr[list[i].id] = list[i].childs[0].id;
        }
      }
      this.activeNames = arr;
      this.lodding = false;
      // this.lodding.close();
    },
    go() {
      if (this.activeData.indexPath.length > 1) {
        this.activeNames[this.activeData.indexPath[0]] =
          this.activeData.indexPath[1];
        this.$forceUpdate();
      } else {
        // console.log(this.$refs[this.activeData.index]);
      }
      // scroll
      this.$refs[
        this.activeData.indexPath.length > 1
          ? this.activeData.indexPath[0]
          : this.activeData.index
      ][0].scrollIntoView({
        behavior: "smooth",
      });
    },
    // detaile
    toDetail(parentId, selfId) {
      // console.log(parentId, selfId);
      this.$router.push({
        name: "about",
        query: { ids: [parentId, selfId] },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home_content {
  width: 100%;
  height: 100%;
}

.tabs {
  text-align: center;
  // top: 50px;
  // left: 50px;
  position: relative;
}
.el-tabs {
  // background-color: rgba(0, 0, 0, 0.1);
  // color: #000;
  // border-radius: 17px;
  // left: 0px;
  // top: 0px;
  // width: 315px;
  // height: 36px;
  // position: relative;
}
/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #538fff !important;
}
/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 137px !important;
  border-radius: 17px;
  top: 0px !important;
  left: -18px !important;
  position: absolute !important;
  z-index: 1;
}
/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}
/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 20px !important;
  // width: 104px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  z-index: 2;
}
</style>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isCollapse: false,
    isMobile: false,
    list: [],
    activeNum: 0,
    activeData: {},
  },
  getters: {
    isCollapse(state) {
      return state.isCollapse;
    },
    isMobile(state) {
      return state.isMobile;
    },
    getList(state) {
      return state.list;
    },
    getActiveNum(state) {
      return state.activeNum;
    },
    getActiveData(state) {
      return state.activeData;
    },
  },
  mutations: {
    SET_ISCOLLAPSE(state) {
      state.isCollapse = !state.isCollapse;
    },
    SET_ISMOBILE(state, data) {
      state.isMobile = data;
    },
    SET_LIST(state, data) {
      state.list = data;
    },
    SET_ACTIVENUM(state) {
      state.activeNum++;
    },
    SET_ACTIVEDATA(state, data) {
      state.activeData = data;
    },
  },
  actions: {
    set_isCollapse({ commit }) {
      commit("SET_ISCOLLAPSE");
    },
    set_isMobile({ commit }, data) {
      commit("SET_ISMOBILE", data);
    },
    set_list({ commit }, data) {
      commit("SET_LIST", data);
    },
    add_activeNum({ commit }, data) {
      commit("SET_ACTIVENUM");
      commit("SET_ACTIVEDATA", data);
    },
  },
  modules: {},
});
